import search from './../../../assets/icon/Search_light.svg';
import share from './../../../assets/icon/fi_share-2.svg';
import Home from './../../../assets/icon/home.svg';
import { Link } from 'react-router-dom';
const Menu = ({ hideMenu, searching, showSocial,clickHome }) => {
    return (
        <>
            <div className="popUpMenu" onClick={hideMenu}> </div>
            <ul className="menu">
                <li className='rowMenuItem' onClick={searching}>
                    <img src={search} alt="icon" />
                    <span>جستجو سریع</span>
                </li>
                <li className='rowMenuItem' onClick={showSocial}>
                    <img src={share} alt="icon" />
                    <span>شبکه های اجتمایی</span>
                </li>
                <li>
                    <Link className='rowMenuItem' to="/" onClick={clickHome}>
                        <img src={Home} alt="icon" />
                        <span>خانه</span>
                    </Link>
                </li>
            </ul>
        </>
    );
}
export default Menu;