import { Routes, Route, useLocation } from "react-router-dom";
import Index from "../shareComponent/Index";
import rout from "./rout";
const AnimationRout = () => {

   return (
      <Routes>
         {rout.map((routing,index) => {
            return <Route {...routing} key={index}  />
         })}
       
      </Routes>
   );
}

export default AnimationRout;