import { Link } from 'react-router-dom';
import leftIcon from './../../assets/icon/arrow-left.svg';
import rightIcon from './../../assets/icon/arrow-right.svg';
import Footer from '../shareComponent/footer/Footer';
import Media from '../shareComponent/socialMedia/Media';


const Video = () => {
    return (
        <div className='video-unit'>
            <video width="315px" height="200px" controls poster="https://ag777.com/img/AGRed.png">
                <source src="https://ag777.com/img/Introduction_ag.mp4" type="video/mp4" />
            </video>

            <div className='nextPrevItemArrow' >
                <Link className='nextBtn' to="/AboutUs">
                    <img src={rightIcon} alt="rightIcon" />
                </Link>
                <Link className='prevBtn' to="/">
                    <img src={leftIcon} alt="leftIcon" />
                </Link>
            </div>
            <Footer>
                <Media />
            </Footer>
        </div>
    );
}

export default Video;