import { Link } from 'react-router-dom';
import darhboard from './../../assets/icon/darhboard.svg';
import leftIcon from './../../assets/icon/arrow-left.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../shareComponent/footer/Footer';
import Spinner from '../spiner/Spinner';

const Categury = () => {
    const [categury, setCategury] = useState([])
    const [load,setLoad]=useState(false)
    useEffect(() => {
       try{
        setLoad(false)
         axios.get(`https://ag777.com/api/catalog/category/parent?token=QSBiJF6Rk%26F5%262yLk1%qz^2*Up`).then((res) => {
            setCategury(res.data);
            localStorage.setItem('categoryes', JSON.stringify(res.data))
        setLoad(true)
   
        }).catch((err) => {
            console.log(err + 'error')
            setCategury(JSON.parse(localStorage.getItem('categoryes')));
        })
       }
        catch (ex) {
            console.log("error.status:", ex);
        }
    }, [])



    if (!load) return <Spinner/>
    return (
        <>
            <div className='position-relative w-100'>
                <div className='d-flex flex-direction-col align-items-center categuryText'>
                    <div className="unitTitleCategury">
                        <img src={darhboard} alt='logo' />
                        <span className='titleCategury'>دسته بندی محصولات</span>
                    </div>
                    <div className="unitProductCatefury">
                        {
                            categury.map((cat,Index) => {
                                return <div key={Index} className='w-50 d-flex justify-content-center align-items-center aspect'>
                                    <span className='itemCategury'>
                                        {/* <Link to="/categury/product/1" > */}
                                        <Link to={`/categury/product/${cat.id}`} >
                                            <img className='imagesCategury' src={`https://ag777.com/uploads/${cat.image}`} alt="food" />
                                            <p>{cat.name}</p>
                                        </Link>
                                    </span>
                                </div>
                            })
                        }
                        <div className="unitTitleCategury">
                            <img src={darhboard} />
                            <span className='titleCategury'>شرکت کارآفرینی ای جی</span>
                        </div>
                    </div>
                </div>
                <div className='nextPrevItemArrow' >
                    <Link className='nextBtn'>
                    </Link>
                    <Link className='prevBtn' to="/AboutUs">
                        <img src={leftIcon} alt="leftIcon" />
                    </Link>
                </div>
                <Footer/>
            </div>
           
        </>
    );
}

export default Categury;