import share from './../../../assets/images/location.png';
import tell from './../../../assets/icon/tell.svg';
import { Children, useState } from "react";
import ContactUs from '../ContactUs';
import SharedMedia from '../SharedMedia';
import { Link } from 'react-router-dom';
import Media from '../socialMedia/Media';
const Footer = ({ children }) => {
    const [tel, setTel] = useState(false)
    const [Shared, setShared] = useState(false);

    const closePopUp = () => {
        setShared(false)
        setTel(false)
    }

    return (
        <footer className='d-flex justify-content-center align-items-center'>
            
            {children}

            {tel && <ContactUs onClick={() => closePopUp()} />}
            {Shared && <SharedMedia onClick={() => closePopUp()} />}
            <div className='w-50 d-flex justify-content-evenly align-items-center'>
                <Link  className='d-flex' onClick={() => setShared(!Shared)}>
                    <img src={share} alt="share" />
                </Link>

                <Link className='d-flex'  to="tel:02146096724">
                    <img src={tell} alt="tell" />
                </Link>
            </div>
        </footer>
    );
}

export default Footer;