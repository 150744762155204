const SearchList = ({ name ,image ,id ,onClick }) => {
    return (
        <>
            <div className="rowResualtSearch" id={id} onClick={onClick}>
                <span className="name-product">{name}</span>
                <img className="image-product" src={`https://ag777.com/images/products/${image}`}/>
            </div>
        </>
    );
}

export default SearchList;