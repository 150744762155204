import Index from "./../shareComponent/Index";
import AboutUs from "./../about/AboutUs";
import Video from "./../about/Video";
import Categury from "./../categury/Categury";
import Product from "./../product/Product";
import DetailesProdct from "../product/DetailesProduct";
import Products from "../product/Products";
const rout = [
    { path: "/", exact: true, Component: Index },
    { path: "/aboutUs", Component: AboutUs },
    { path: "/video", Component: Video },
    { path: "/categury/", Component: Categury },
    { path: "/categury/product/:id", Component: Product },
    { path: "/categury/product/products/:id", Component: Products },
    { path: "/categury/product/products/DetailesProdct/:id", Component: DetailesProdct},
]
export default rout;

 