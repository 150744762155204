import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout";
import './assets/styles/main.css';
import AnimationRout from "./components/routes/AnimationRout";
import { useEffect, useState } from "react";


const App = () => {
  const [width] = useState(window.innerWidth)

  // if (width > 560) return (
  //   <div className="centerElement">
  //     <p >
  //       این اپلیکیشن در حال حاضر برای موبایل تولید شده است
  //     </p>
  //     <div className="loader7">
  //       <span></span>
  //       <div className="greenlight"></div>
  //     </div>
  //   </div>
  // )

  return (
    <BrowserRouter>
      <Layout>
        <AnimationRout />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
